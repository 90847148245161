import React from 'react'
import { FilterContextProvider } from '../../context/filterContext'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import BlogDetail from './BlogDetail1'
import Menu from "./Menu";
import BlogBottom from './BlogBottom';

const HomeDetail1 = () => {
  return (
    <>
    <Navbar />
    <Menu />
    <FilterContextProvider>
        <BlogDetail/>
    </FilterContextProvider>
    <BlogBottom />
      <Footer />
      </>
  )
}

export default HomeDetail1