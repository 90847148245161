import React from "react";
import HoverBtn from "../main/HoverBtn";
import "./service.css";
import ServiceCard from "./serviceCard";
import ServiceQuestion from "./serviceQuestion";
import bg  from './The-Right-Peace-Of-Mind.png';

const TopBox = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5">
          <h1 className="services-title mt-md-5">
            The Right
            <br /> Peace Of Mind
          </h1>
          <p className="service-para">
            We understand you require the peace of mind that comes with making
            the right investment with the right IT services company.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <img
            src={bg}
            alt=""
            className="img-fluid m-4"
            style={{width:"300px"}}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <h1 className="text-center services-title1">
          Welcome to TexasiNet
          </h1>
          <p className="service-para1">
          the IT solutions provider that helps small and medium-sized businesses compete with larger <br/>firms. We're passionate about helping organizations like yours harness the power of <br/> technology to streamline operations, boost productivity, and achieve growth.<br/> Here are the ways we can help:
          </p>
        </div>
      </div>
      <ServiceCard />
      <div className="row card-container mt-2 mb-2">
        <div className="col-md-7">
          <h1 className="text-center fs-20" style={{fontFamily:"Times New Roman"}}>At TexasiNet,</h1>
          <p className="text-center elementor-element-087ad86">
          Contact us today to learn how we can help you grow your business faster and more profitably with our customized IT solutions!
          </p>
        </div>
      </div>
      <HoverBtn />
      <ServiceQuestion />
    </div>
  );
};
export default TopBox;

