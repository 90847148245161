import React, { useEffect } from "react";
import "./detail.css";
import BlogCard from "./BlogCard";
import { useParams } from "react-router-dom";
import { useBlogContext } from "../../context/BlogContext";
import {useFilterContext} from "../../context/filterContext";
import {Helmet} from "react-helmet-async";

const API = "https://admin.texasinet.com/api/blog/";

const BlogDetail = () => {
    const {getSingleBlog,singleBlog}=useBlogContext();
    const {filter_blogs}=useFilterContext();
    const {image,long_description,meta_description,meta_keywords,name,meta_title,category_name}=singleBlog;
    console.log("category_name",category_name);
    const {id}=useParams();
    useEffect(()=>{
        getSingleBlog(`${API}${id}`);
    },[])
  

  return (
    <>
     <Helmet>
      <title>{meta_title}</title>
      <meta  name="description" content={meta_description}/>
      <meta name="keywords" content={meta_keywords}/>
      <link rel="canonical" href="/singleBlog"/>
      <meta name="robots" content="noindex"/>
    </Helmet>
      <div className="container-fluid">
        <div className="main">
          <div id="box">
            <p style={{color:"#cecaca",fontWeight:"800",fontSize:"1rem"}}>{category_name}</p>
            <img
              id="thisimgmxcv"
              src={`https://admin.texasinet.com/${image}`}
              style={{ width: "100%", height: "495px" }}
              alt=""
            />
            <p className="mt-3">
              <strong>{name}</strong>
            </p>
            <h3>{meta_description}</h3>
            <h4>{meta_keywords}</h4>
            <p>
            <div
      dangerouslySetInnerHTML={{__html: long_description}}/>
            </p>
            <h2 className="text-center">PEOPLE ALSO READ</h2>
            <BlogCard blog={filter_blogs}/>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogDetail;
