import { createRoot } from "react-dom/client";
import { AppProvider } from "./context/BlogContext";
import {HelmetProvider} from "react-helmet-async";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
  <AppProvider>
  <App />
  </AppProvider>
  </HelmetProvider>
);
