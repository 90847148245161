import React from "react";
import Footer from "../Footer/Footer";
import Menu from "../Services/menu";
import Navbar from "../Navbar/Navbar";
import TopBox from "../Services/topBox";
import "./service.css";
import ServiceBottom from "./serviceBottom";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <>
    <Helmet>
      <title>TexasiNet Focus</title>
      <meta  name="description" content="TexasiNet provide industry-leading technologies allowing your company to focus on business growth, profitability and operational excellence. We provide a broad..."/>
      <link rel="canonical" href="/services"/>
      <meta name="robots" content="noindex"/>
    </Helmet>
      <Navbar />
      <Menu />
      <div className="container-fluid">
        <div className="main">
          <TopBox />
        </div>
      </div>
      <ServiceBottom />
      <Footer />
    </>
  );
};
export default Services;

