import React from "react";
import { BsPlusLg } from "react-icons/bs";
import ServiceCardLeft from "./serviceCardLeft";
import Cloud_Solutions from "../assets/Cloud-Solutions-1.gif";
import BusinessContinuity from "../assets/Business-Continuity-Planning.gif";
import Cybersecurity from "../assets/Cybersecurity-1.gif";
import ManageIT from "../assets/managed-it-services-2.gif";
import One from "../assets/95189808b18b73bf6b9b9a8373f7b22d36654d86db6525917c6ab8ed5e26b90f-3.gif";

const ServiceCard = () => {
  return (
    <>
      <div className="row mt-5 mb-4 mx-md-5">
        <div className="col-md-6">
        <img
            src={Cybersecurity}
            alt="superman"
            className="service-p-image"
          />
        </div>
        <div className="col-md-6">
          <h1 id="bav-title">Cybersecurity</h1>
          <h6 id="service-sub-title">
          With cyber threats rising, you need a robust security plan to keep your business safe.
          </h6>
          <p className="elementor-element-70a0dd5">
Our cybersecurity experts will work with you to create a custom solution that addresses your specific risks and vulnerabilities. From virus protection to encryption, we'll ensure your valuable data and systems stay secure.

          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#left"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="left">
            <ul className="text-left service-ul mt-3">
              <li>Reduce cyber security risks and vulnerabilities.</li>
              <li>Managed security services.</li>
              <li>Protect against security breaches.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-1 mb-4 mx-md-5" id="small-mobile-none">
        <div className="col-md-6">
          <h1 id="bav-title">Cloud Solutions</h1>
          <h6 id="service-sub-title">
          The cloud is a game-changer for businesses looking to scale cost-effectively and collaborate more effectively.
          </h6>
          <p className="elementor-element-70a0dd5">
          At TexasiNet, we offer a range of cloud services to help you tap into these benefits. Whether you need help migrating your data to the cloud, setting up cloud-based apps, or managing your cloud environment, we've got you covered.
          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#right"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="right">
            <ul className="text-left service-ul mt-3">
              <li>Increase scalability,reliability and agility.</li>
              <li>Cloud migration and hosting</li>
              <li>Cloud backup and disaster recovery</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={Cloud_Solutions}
            alt="superman"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row mt-5 mb-4 mx-md-5" id="mobile-show">
        <div className="col-md-6">
          <img
            src={Cloud_Solutions}
            alt="superman"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <h1 id="bav-title">Cloud Solutions</h1>
          <h6 id="service-sub-title">
          The cloud is a game-changer for businesses looking to scale cost-effectively and collaborate more effectively.
          </h6>
          <p className="elementor-element-70a0dd5">
          At TexasiNet, we offer a range of cloud services to help you tap into these benefits. Whether you need help migrating your data to the cloud, setting up cloud-based apps, or managing your cloud environment, we've got you covered.
          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#left"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="left">
            <ul className="text-left service-ul mt-3">
              <li>Increase scalability,reliability and agility.</li>
              <li>Cloud migration and hosting</li>
              <li>Cloud backup and disaster recovery</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-4 mx-md-5">
        <div className="col-md-6" >
          <img
            src={ManageIT}
            alt="superman"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <h1 id="bav-title">Managed IT Services</h1>
          <h6 id="service-sub-title">
            Scalable IT Support To Take You From Where You Are To Where You Want
            To Be.
          </h6>
          <p className="elementor-element-70a0dd5">
            We provide a full range of Managed IT Services and IT Consulting
            support for small and medium sized businesses across DFW. With our
            service plan, you can enjoy the peace of mind that comes with
            knowing that your IT infrastructure is up to date, regularly
            maintained, and consistently monitored
          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#left2"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="left2">
            <ul className="text-left service-ul mt-3">
              <li>Instand world-class, afforadable IT</li>
              <li>IT outsourcing for business leaders</li>
              <li>IT augmentation for IT leaders</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-4 mx-md-5" id="mobile-show">
        <div className="col-md-6">
          <img
            src={BusinessContinuity}
            alt="superman"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <h1 id="bav-title">Business Continuity Planning</h1>
          <h6 id="service-sub-title">
          Disaster can strike any time, but with our help, you can minimize the impact on your business.
          </h6>
          <p className="elementor-element-70a0dd5">
          We'll work with you to create a robust business continuity plan that includes data backup and recovery, disaster recovery strategies, and ongoing testing and monitoring. So, no matter what happens, you'll be ready to keep your business running.

          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#left"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="left">
            <ul className="text-left service-ul mt-3">
              <li>Business continuity and disaster recovery Planning</li>
              <li>Data backup and recovery</li>
              <li>Proactive Protection</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-1 mb-0 mx-md-5" id="small-mobile-none">
        <div className="col-md-6">
          <h1 id="bav-title">Business Continuity Planning</h1>
          <h6 id="service-sub-title">
          Disaster can strike any time, but with our help, you can minimize the impact on your business.
          </h6>
          <p className="elementor-element-70a0dd5">
          We'll work with you to create a robust business continuity plan that includes data backup and recovery, disaster recovery strategies, and ongoing testing and monitoring. So, no matter what happens, you'll be ready to keep your business running.

          </p>
          <button
            className="read-more"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#right1"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Read More
            <BsPlusLg style={{ float: "right", marginTop: "8px" }} />
          </button>
          <div className="collapse" id="right1">
            <ul className="text-left service-ul mt-3">
              <li>Business continuity and disaster recovery Planning</li>
              <li>Data backup and recovery</li>
              <li>Proactive Protection</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={BusinessContinuity}
            alt="superman"
            className="service-p-image"
          />
        </div>
      </div>
      <ServiceCardLeft
        title="Always Expanding"
        subtitle="Customized IT Support Solutions That Fit Your Business."
        image={One}
        para="We are committed to the continued growth and development of our staff and technology. Our clients’ success is our first priority, and we are always finding new and better ways to serve them."
        point="Office Support Plans"
        point1="Unified Communications"
        point2="Custom technology roadmaps"
      />
    </>
  );
};
export default ServiceCard;