import React from "react";
import TopBox from "./TopBox";
import "./blog.css";
import BlogCard from "./BlogCard";
import {useFilterContext} from "../../context/filterContext";


const Blog = () => {
  const {filter_blogs,all_blogs,updateFilterValue,filters:{category_name}}=useFilterContext();
  const getUniqueData=(data,property)=>{
    let newVal=data.map((curElem)=>{
       return curElem[property];
    })
    return (newVal=[...new Set(newVal)])
}
  //we need unique data
  const categoryOnlyData =getUniqueData(all_blogs,"category_name");
  return (
    <>
      <div className="container-fluid">
        <div className="main">
          <TopBox />
          <div className="category_navbar">
        <div className="Category_btn_group">
          {categoryOnlyData.map((curElem,index)=>{
            return(
              <button
                className="Category_btn_group_item"
                key={index}
                type="button"
                value={curElem}
                name="category_name"
                onClick={updateFilterValue}
              >
                {curElem}
              </button>
            )
          })}
              
         
        </div>
      </div>
          <BlogCard blog={filter_blogs}/>
        </div>
      </div>
    </>
  );
};
export default Blog;
