import React ,{useState} from "react";
import { NavLink } from "react-router-dom";
import './Load.css'
const BlogCard = ({ blog }) => {
  const [displayedBlogs, setDisplayedBlogs] = useState(9);

  const handleLoadMore = () => {
    setDisplayedBlogs(displayedBlogs + 9);
  };




  return (
    <>
    <div className="card-container1">
    {blog.slice(0,displayedBlogs).map((curElem)=>{
      const {
    id,
    name,
    image
  } = curElem;
      return(
        <NavLink to={`/singleBlog/${id}`} style={{textDecoration:"none"}}>
        <div className="card" key={id}>
            <div className="image-session">
              <img src={`https://admin.texasinet.com/${image}`} alt={name} />
            </div>
            <div className="article">
              <a href="#as">{name}</a>
            </div>
            <div className="blog-view">
              <a href="#as">Read more</a>
            </div>
          </div>
        </NavLink>
      )
    })}
  
    </div>
      {displayedBlogs < blog.length && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Load more
        </button>
      )}
      </>
    
  );
};
export default BlogCard;
