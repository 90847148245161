import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import DoingRight from "./DoingRight";
import { RxDotFilled } from "react-icons/rx";
import GridCol from "./GridCol";
import Lastly from "./lastly";
import Right from "./Right";
import TwoCol from "./TwoCol";
import HoverBtn from "./HoverBtn";
import Mobile from "./Mobile";
import Better1 from "../assets/Its-better-to-have-a-partner.png";
import Moreexpertise from "../assets/More-expertise.png";
import GetBetter from  "../assets/Get-better-communication.png";
import CloudIT from "../assets/CLOUD-IT-1.svg";
import MANAGEDIT from "../assets/MANAGED-IT.svg";
import SECUREIT from "../assets/SECURE-IT.svg";
import CONTINUITYIT from "../assets/CONTINUITY-IT.svg";

const Card = () => {
  return (
    <>
      <div className="row card-container">
        <div className="col-md-7">
          <h1 className="text-center cart-me">We Do IT better.</h1>
          <h2 className="text-center card-sub1">Better Security | Better Solutions | Better Support</h2>
          <p className="text-center elementor-element-087ad86">
          Welcome to our IT solutions hub – your one-stop shop for all your organization's technology needs. We believe that better analysis leads to better technology, so we specialize in providing customized solutions perfectly aligned with your goals and budget. Here's what makes us stand out from the crowd:
          </p>
        </div>
      </div>
      <div className="row m-md-5">
        <div className="col-lg-6 col-xl-3 col-xxl-3 col-sm-3  col-md-6">
          <div className="card m-md-3">
            <div className="inner-box">
              <div className="card-front">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "20px" }}
                    src={CloudIT}
                    alt=""
                  />
                </span>
                <div
                  className="rt"
                  style={{ lineHeight: "8px", marginBottom: "57px" }}
                >
                  <h3 className="card-title">Cloud</h3>
                  <BsPlusCircle className="plusicon" />
                </div>
              </div>
              <div className="card-back">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "50px" }}
                    src={CloudIT}
                    alt=""
                  />
                </span>
                <ul className="ul">
                  <li>
                    <RxDotFilled className="dot-p" />
                    Cloud Migration Roadmap
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Cloud Implementation
                  </li>
                  <li>
                    {" "}
                    <RxDotFilled className="dot-p" />
                    Administration and Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 col-xxl-3 col-sm-3  col-md-6">
          <div className="card m-md-3">
            <div className="inner-box">
              <div className="card-front d">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "20px" }}
                    src={MANAGEDIT}
                    alt=""
                  />
                </span>
                <div
                  className="rt"
                  style={{ lineHeight: "8px", marginBottom: "57px" }}
                >
                  <h3 className="card-title">Monitoring</h3>
                  <BsPlusCircle className="plusicon" />
                </div>
              </div>
              <div className="card-back">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "50px" }}
                    src={MANAGEDIT}
                    alt=""
                  />
                </span>
                <ul className="ul">
                  <li>
                    <RxDotFilled className="dot-p" />
                    Monitoring and Alerting of All Managed Devices
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Ongoing Health and Status
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Network Availability
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 col-xxl-3 col-sm-3  col-md-6">
          <div className="card m-md-3">
            <div className="inner-box">
              <div className="card-front s">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "20px" }}
                    src={SECUREIT}
                    alt=""
                  />
                </span>
                <div
                  className="rt"
                  style={{ lineHeight: "8px", marginBottom: "57px" }}
                >
                  <h3 className="card-title">Security</h3>
                  <BsPlusCircle className="plusicon" />
                </div>
              </div>
              <div className="card-back">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "50px" }}
                    src={SECUREIT}
                    alt=""
                  />
                </span>
                <ul className="ul">
                  <li>
                    <RxDotFilled className="dot-p" />
                    Complete Endpoint Protection
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Data Loss Prevention
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Next Generation Firewall
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 col-xxl-3 col-sm-3  col-md-6">
          <div className="card m-md-3">
            <div className="inner-box">
              <div className="card-front n">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "20px" }}
                    src={CONTINUITYIT}
                    alt=""
                  />
                </span>
                <div
                  className="rt"
                  style={{ lineHeight: "8px", marginBottom: "57px" }}
                >
                  <h3 className="card-title">Network Development</h3>
                  <BsPlusCircle className="plusicon" />
                </div>
              </div>
              <div className="card-back">
                <span className="pro">
                  <img
                    style={{ width: "62px", marginTop: "50px" }}
                    src={CONTINUITYIT}
                    alt=""
                  />
                </span>
                <ul className="ul">
                  <li>
                    <RxDotFilled className="dot-p" />
                    Planning, Architecture and Engineering
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    On-Time Network Deployment
                  </li>
                  <li>
                    <RxDotFilled className="dot-p" />
                    Procurement Of Hardware and Software
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2 mb-4">
        <div className="elementor-background-overlay"></div>
        <div className="background-overlay">
          <h2 className="heading-title m-3">
            We're on a mission to make IT better.
          </h2>
          <h5 id="heading">We start with your IT need-Always</h5>
        </div>
      </div>
      <TwoCol
        image={Better1}
        para="When it comes to your organization's information technology, you need a partner you can rely on. That's where we come in. Our expert team handles every aspect of your IT infrastructure, including hardware, software, and website management. With us by your side, you can focus on growing your business faster and more profitably."
        title="It's better to have a partner who's in sync"
      />
      <Right
        image={Moreexpertise}
        title="More expertise means better execution"
        para="With over 20 years of experience in the industry, we're the leading firm in delivering high-quality services and value to our customers. Our long-standing relationships with clients are a testament to the quality of our work. We use state-of-the-art network monitoring and management tools to keep your systems running smoothly and effectively, giving you the speed you need to stay ahead."
      />
      <Mobile
        image={Moreexpertise}
        para="With over 20 years of experience in the industry, we're the leading firm in delivering high-quality services and value to our customers. Our long-standing relationships with clients are a testament to the quality of our work. We use state-of-the-art network monitoring and management tools to keep your systems running smoothly and effectively, giving you the speed you need to stay ahead."
        title="More expertise means better execution"
      />
      <TwoCol
        className="mobile"
        image={GetBetter}
        para="At our IT solutions hub, you're more than just a client – you're a partner. We believe that exceptional customer service starts with answering the phone when you call. Our focus is always on providing you with the best possible experience, so we're committed to responding on time to all customer issues until they're fully resolved, beyond your expectations."
        title="Get better communication"
      />
      <div className="row card-container mt-2 mb-2">
        <div className="col-md-7">
          <h1 className="text-center fs-20" style={{fontFamily:"Times New Roman"}}>Ready to take your organization's technology to the next level?</h1>
          <p className="text-center elementor-element-087ad86">
          Contact us today to learn how we can help you grow your business faster and more profitably with our customized IT solutions!
          </p>
        </div>
      </div>
      <HoverBtn />
      <GridCol />
      <div className="row">
        <DoingRight />
      </div>
      <Lastly />
    </>
  );
};
export default Card;

