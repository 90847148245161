import { createContext, useContext, useEffect, useReducer } from "react";
import {useBlogContext} from "./BlogContext";
import reducer from "../Reducers/filterReducer";

const FilterContext=createContext();
const initialState={
    filter_blogs:[],
    all_blogs:[],
    filters:{
        text:"",
        category_name:"All"
    }
}


export const FilterContextProvider=({children})=>{
    const {blogs} =useBlogContext();

    const [state,dispatch]=useReducer(reducer,initialState);

    //update filter value

    const updateFilterValue=(event)=>{
        let name=event.target.name;
        let value=event.target.value;

        return dispatch({type:"UPDATE_FILTERS_VALUE",payload:{name,value}})
    }
    
    //to filter blog
    useEffect(()=>{
        dispatch({type:"FILTERS_BLOGS"})
    },[blogs,state.filters])

    useEffect(()=>{
        dispatch({type:"LOAD_FILTER_BLOGS",payload:blogs})
    },[blogs])
return(
    <FilterContext.Provider value={{...state,updateFilterValue}}>updateFilterValue
        {children}
    </FilterContext.Provider>
)
}

export const useFilterContext=()=>{
    return useContext(FilterContext)
}