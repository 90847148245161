import React from "react";
import Footer from "../Footer/Footer";
import Menu from "../About/menu";
import Navbar from "../Navbar/Navbar";
import TopBox from "./Topbox";
import "./about.css";
import WhyBox from "./whyBox";
import AboutTable from "./aboutTable";
import Servicebtn from "./servicebtn";
import ServiceBottom from "../Services/serviceBottom";
import ServiceQuestion from "../Services/serviceQuestion";
import {Helmet} from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
      <title>TexasiNet Focus</title>
      <meta  name="description" content="TexasiNet provide industry-leading technologies allowing your company to focus on business growth, profitability and operational excellence. We provide a broad..."/>
      <link rel="canonical" href="/about"/>
      <meta name="robots" content="noindex"/>
    </Helmet>
      <Navbar />
      <Menu />
      <div className="container-fluid">
        <div className="main">
          <TopBox />
          <WhyBox />
          <AboutTable />
          <Servicebtn />
          <ServiceQuestion />
        </div>
      </div>
      <ServiceBottom />
      <Footer />
    </>
  );
};
export default About;
