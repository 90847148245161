import React from "react";
import Why from "../assets/Asset-10-8.png";

const WhyBox = () => {
  return (
    <>
      <div className="row card-container mt-2 mb-2 bg-light">
        <div className="col-md-7">
          <h1 className="text-center why">Our approach is simple</h1>
          <p className="text-center elementor-element-087ad86 pb-2">
          We're all about you. We've taken the time to identify our ideal client, and if you fit that profile, then we're confident that we'll be the best technology company you'll ever work with. We focus on providing holistic, security-focused solutions tailored to your specific needs. Our goal is to make your life and business easier, not more complicated. We understand that technology can be overwhelming, so we take the time to engage with you. We view every client relationship as a partnership and are committed to your success.
          </p>
        </div>
      </div>
      <div>
        <p className="text-center">
          <img
            width="328"
            height="167"
            src={Why}
            className="attachment-large size-large"
            alt=""
            loading="lazy"
            sizes="(max-width: 328px) 100vw, 328px"
          />
        </p>
        <div className="row card-container mt-2 mb-2">
        <div className="col-md-7">
          <h1 className="text-center why">Partner with us</h1>
          <p className="text-center elementor-element-087ad86">
        When you partner with us, you're working with an experienced and reliable technology provider fully invested in your success. We offer efficient and effective IT solutions that fit the framework of your enterprise. We provide round-the-clock technical support so that you can focus on what you do best: running your business.
        </p>
        <p className="text-center elementor-element-087ad86 pb-1">
        We take pride in our ability to deliver exceptional customer service. Our team is dedicated to ensuring that you're satisfied with our services, and we're always available to answer any questions you may have.
        </p>
        </div>
      </div>
      <div></div>
        
      </div>
    </>
  );
};
export default WhyBox;

