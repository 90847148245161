const filterReducer=(state,action)=>{
switch(action.type){
    case "LOAD_FILTER_BLOGS":
        return {
            ...state,
            filter_blogs:[...action.payload],
            all_blogs:[...action.payload]
        }
    case "UPDATE_FILTERS_VALUE":
        const {name,value}=action.payload;
        return {
            ...state,
            filters:{
                ...state.filters,
                [name]:value
            }
        }
    case "FILTERS_BLOGS":
        let {all_blogs}=state;
        let temFilterBlog=[...all_blogs];
        const {text,category_name}=state.filters;
        console.log(category_name);
        if(text){
            temFilterBlog = temFilterBlog.filter((curElem)=>{
                return curElem.name.toLowerCase().includes(text);
            })
        }
        if(category_name){
            console.log(category_name);
            temFilterBlog=temFilterBlog.filter((curElem)=>{
                return curElem.category_name===category_name;
            }) 
        }
        return{
            ...state,
            filter_blogs:temFilterBlog,
        }
    default:return state
}
}
export default filterReducer;