import React from "react";
import "./main.css";
import { Typewriter, Cursor  } from "react-simple-typewriter";

 


const TopBox = () => {
  const text = Typewriter({
    words: [
      "Managed IT Services",
      "Everything Cloud",
      "Cybersecurity",
      "Business Continuity"
    ],
    loop:{}
  });

   
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 marg">
          <h5 className="elementor-heading-title">
            
          ⸺ You Manage Your Business.
          </h5>
          <h3 className="mx-3 tect-p">WE’LL MANAGE  THE TECHNONLOGY</h3> 
          <h1 className="tect-p mx-3">BEHIND IT.<span id="cyber"> {text}   <Cursor/></span></h1>
           
        </div>
        <div className="col-md-6">
          <video
            playsinline=""
            autoplay="autoplay"
            muted="muted"
            loop="loop"
            poster="https://assets9.lottiefiles.com/render/kntbdjvz.png"
            className="w-full rounded-t-lg img-fluid fan "
          >
            <source
              type="video/mp4"
              src="https://assets1.lottiefiles.com/render/kntbdjvz.mp4"
            />
          </video>
        </div>
      </div>
    </div>
  );
};
export default TopBox;
