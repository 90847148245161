import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Services from "./Components/Services/Services";
import Blog from "./Components/Blog/HomeBlog";
import About from "./Components/About/About";
import SingleBlog from "./Components/BlogDetail/HomeDetail";
import ScrollToTop from "react-scroll-to-top";
import BlogDetail from "./Components/BlogDetail/HomeDetail1";
export default function App() {
  return (
    <BrowserRouter>
    <ScrollToTop smooth color="#002c6f" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/singleBlog/:id" element={<SingleBlog/>}/>
        <Route path="/singleBlogs/:id" element={<BlogDetail/>}/>
      </Routes>
    </BrowserRouter>
  );
}
