import React from "react";
import DallasCity from "../assets/Dallas-City-8.png";
import FortWorth from "../assets/Fort-Worth-City-8.png";

const ServiceBottom = () => {
  return (
    <div id="service-wave">
      <h1 className="text-center service-wave-title">Here we are!</h1>
      <div className="service-wave-2">
        <div className="col-md-6 mb-4" style={{ textAlign: "center" }}>
          <img
            width="436"
            height="245"
            src={DallasCity}
            className="place"
            alt=""
            loading="lazy"
            sizes="(max-width: 436px) 100vw, 436px"
          />
        </div>
        <div className="col-md-6 mb-4" style={{ textAlign: "center" }}>
          <img
            width="477"
            height="245"
            src={FortWorth}
            className="place"
            alt=""
            loading="lazy"
            sizes="(max-width: 477px) 100vw, 477px"
          />
        </div>
      </div>
    </div>
  );
};
export default ServiceBottom;

