import React from 'react'
import { FilterContextProvider } from '../../context/filterContext'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Blog from './Blog'
import BlogBottom from './blogBottom'
import Menu from './menu'
import {Helmet} from "react-helmet-async";

const HomeBlog = () => {
  return (
    <>
     <Helmet>
      <title>TexasiNet Focus</title>
      <meta  name="description" content="TexasiNet provide industry-leading technologies allowing your company to focus on business growth, profitability and operational excellence. We provide a broad..."/>
      <link rel="canonical" href="/blog"/>
      <meta name="robots" content="noindex"/>
    </Helmet>
    <Navbar />
    <Menu />
    <FilterContextProvider>
        <Blog/>
    </FilterContextProvider>
    <BlogBottom />
      <Footer />
      </>
  )
}

export default HomeBlog