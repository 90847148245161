import React from "react";
import monophy from "../assets/monophy.gif";

const Lastly = () => {
  return (
    <div className="col p-md-5">
      <h1 id="lastlytitle">Lastly..</h1>
      <p className="text-center elementor-element-087ad86">
        We recognize that organizations are not just looking for another IT consulting
        company that <br /> will sell,install,fix,
        and refresh technology for them, but a   
        partners who capable of helping <br /> them to manage their environment. 
      </p>
      <p>
        <img
          width="100%"
          height="220"
          src={monophy}
          alt=""
          loading="lazy"
        />
      </p>
    </div>
  );
};
export default Lastly;

